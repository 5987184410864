<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="top_nav">
      <!-- 附加税 -->
      <el-tab-pane label="附加税" name="1">
        <!-- <div class="right_box">
          <el-button type="primary" @click="handleDownloadVat()" size="small">导出</el-button>
          <el-button size="small" @click="printingNew()" type="success">打印</el-button>
        </div> -->
        <div class="big_box4">
          <div class="top_title3">
            <h4>附加税</h4>
            <h5>一般纳税人、小规模纳税人适用</h5>
            <div class="bottom_font clearfix">
              <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
              <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
              <h6 class="right">
                <p>单位：元</p>
                <p>纳税人识别号：<span>{{taxNo}}</span></p>
              </h6>
            </div>
          </div>
          <table class="content" cellspacing="0">
            <tr class="top_bg">
              <td rowspan="4" class="center weight" style="width:12%;">征收项目</td>
              <td rowspan="4" class="center weight" style="width:12%;">征收品目 </td>
              <td colspan="5" class="center weight" style="width:36%">计税（费）依据</td>
              <td rowspan="3" class="center weight" style="width:6%">税率（征收率）</td>
              <td rowspan="3" class="center weight" style="width:6%">本期应纳税（费）额</td>
              <td colspan="3" rowspan="2" class="center weight" style="width:22%">本期减免税（费）额</td>
              <td rowspan="3" class="center weight" style="width:6%">本期已缴纳税（费）额</td>
              <td rowspan="3" class="center weight" style="width:6%">本期应补（退）税（费）额</td>
            </tr>

            <tr class="top_bg">
              <td colspan="2" class="center weight" style="width:12%">增值税</td>
              <td rowspan="2" class="center weight" style="width:6%">消费税</td>
              <td rowspan="2" class="center weight" style="width:6%">营业税</td>
              <td rowspan="2" class="center weight" style="width:6%">合计</td>
            </tr>
            <tr class="top_bg">
              <td class="center weight" style="width:6%">一般增值税</td>
              <td class="center weight" style="width:6%">免抵税额</td>
              <td class="center weight" style="width:10%">减免性质代码</td>
              <td class="center weight" style="width:6%">减免额</td>
              <td class="center weight" style="width:6%">增值税小规模纳税人减征额</td>
            </tr>
            <tr class="top_bg">
              <td class="center" style="width:6%">1</td>
              <td class="center" style="width:6%">2</td>
              <td class="center" style="width:6%">3</td>
              <td class="center" style="width:6%">4</td>
              <td class="center" style="width:6%">5=1+2+3+4</td>
              <td class="center" style="width:6%">6</td>
              <td class="center" style="width:6%">7=5*6</td>
              <td class="center" style="width:10%">8</td>
              <td class="center" style="width:6%">9</td>
              <td class="center" style="width:6%">10</td>
              <td class="center" style="width:6%">11</td>
              <td class="center" style="width:6%">12</td>
            </tr>

            <tr>
              <td><input type="text" v-model="list[0].zsxmDm"></td>
              <td><input type="text" v-model="list[0].zspmDm"></td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].ybzzs" @change="saveCjs"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].zzsmdse" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].xfs" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].yys" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].hj" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].sl1" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].bqynsfe" @change="changeList()"></qzf-str-td>
              <!-- <qzf-str-td :disabled="false" :amount.sync="item.jmxzDm" @change="changeList()"></qzf-str-td> -->
              <td style="width:10%">
                <el-select v-model="list[0].jmxzDm" placeholder="请选择" @change="changeList" size="small">
                  <el-option size="small" v-for="(item,index) in fjsJmList.cswhjss" :key="index" :label="item.zsxmName" :value="`${item.then},${item.zsxmCode},${item.dm2}`">
                  </el-option>
                </el-select>
              </td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].jme" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].phjmse" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].bqyjse" @change="changeList()"></qzf-str-td>
              <qzf-str-td style="width:6%" :disabled="false" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-str-td>
            </tr>

            <tr>
              <td><input type="text" v-model="list[1].zsxmDm"></td>
              <td><input type="text" v-model="list[1].zspmDm"></td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].ybzzs" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].zzsmdse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].xfs" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].yys" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].hj" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].sl1" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].bqynsfe" @change="changeList()"></qzf-str-td>
              <!-- <qzf-str-td :disabled="false" :amount.sync="item.jmxzDm" @change="changeList()"></qzf-str-td> -->
              <td>
                <el-select v-model="list[1].jmxzDm" placeholder="请选择" @change="changeList1" size="small">
                  <el-option v-for="(item,index) in fjsJmList.jyffj" :key="index" :label="item.zsxmName" :value="`${item.then},${item.zsxmCode},${item.dm2}`">
                  </el-option>
                </el-select>
              </td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].jme" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].phjmse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].bqyjse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[1].bqybtse" @change="changeList()"></qzf-str-td>
            </tr>

            <tr>
              <td><input type="text" v-model="list[2].zsxmDm"></td>
              <td><input type="text" v-model="list[2].zspmDm"></td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].ybzzs" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].zzsmdse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].xfs" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].yys" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].hj" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].sl1" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].bqynsfe" @change="changeList()"></qzf-str-td>
              <!-- <qzf-str-td :disabled="false" :amount.sync="item.jmxzDm" @change="changeList()"></qzf-str-td> -->
              <td>
                <el-select v-model="list[2].jmxzDm" placeholder="请选择" @change="changeList2" size="small">
                  <el-option v-for="(item,index) in fjsJmList.dfjyfj" :key="index" :label="item.zsxmName" :value="`${item.then},${item.zsxmCode},${item.dm2}`">
                  </el-option>
                </el-select>
              </td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].jme" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].phjmse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].bqyjse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[2].bqybtse" @change="changeList()"></qzf-str-td>
            </tr>

            <tr v-if="address != '内蒙' && list.length >= 4">
              <td><input type="text" v-model="list[3].zsxmDm"></td>
              <td><input type="text" v-model="list[3].zspmDm"></td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].ybzzs" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].zzsmdse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].xfs" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].yys" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].hj" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].sl1" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].bqynsfe" @change="changeList()"></qzf-str-td>
              <!-- <qzf-str-td :disabled="false" :amount.sync="item.jmxzDm" @change="changeList()"></qzf-str-td> -->
              <td>
                <el-select v-model="list[3].jmxzDm" placeholder="请选择" @change="changeList3" size="small">
                  <el-option v-for="(item,index) in fjsJmList.sljszxsr" :key="index" :label="item.zsxmName" :value="`${item.then},${item.zsxmCode},${item.dm2}`">
                  </el-option>
                </el-select>
              </td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].jme" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].phjmse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].bqyjse" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[3].bqybtse" @change="changeList()"></qzf-str-td>
            </tr>

            <tr>
              <td colspan="10">合计</td>
              <td>{{sum2}}</td>
              <td>{{sum3}}</td>
              <td>{{sum4}}</td>
              <td>{{sum1}}</td>
            </tr>

          </table>

        </div>
      </el-tab-pane>
      <!-- 附加税 end -->
    </el-tabs>
    <div class="save_box">
      <el-button type="primary" @click="saveValues()" size="small" plain>保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'additionalTax',
  data() {
    return {
      address: "",
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: [{},{},{},{}],
      fjsJmList: [],
      period: '',
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  methods: {
    // 公式
    changeList(){

    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.right_box {
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}

.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}

</style>