<template>
  <el-dialog title="影像预览" v-model="showImagePreview" width="1200px" :close-on-click-modal="false"
    :close-on-press-escape="false">
    <el-carousel :class="changeIndex == 0 ? 'leftBtnNone' : changeIndex == imgSrc.length - 1 ? 'rightBtnNone' : ''"
      :autoplay="false" trigger="click" height="700px" @change="carousel">
      <el-carousel-item v-for="item in imgSrc" :key="item">
        <el-scrollbar :maxHeight="700">
          <el-image :src="item" style="width: 100%"></el-image>
        </el-scrollbar>
      </el-carousel-item>
    </el-carousel>
    <div style="text-align: center">
      {{ changeIndex + 1 }} / {{ imgSrc.length }} 张
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { getVoucherPng } from '@/api/voucher'
import { wbVoucherFxOther } from '@/api/export'
import { ElMessage } from 'element-plus'
const showImagePreview = ref(false)
const imgSrc = ref([])
const changeIndex = ref(0)
const init = (row) => {
  if (row.fromType != '6') {
    getVoucherPng({ voucherId: row.id }).then(res => {
      if (res.data.data.image) {
        showImagePreview.value = true
        let arr = []
        res.data.data.image.map(v => {
          if (v.indexOf("https") > -1) {
            arr.push(v)
          } else {
            arr.push('https://file.listensoft.net' + v)
          }
        })
        imgSrc.value = arr
      }
    })
  } else {
    let param = {
      outType: "pdf",
      exportType: "voucher_image_view",
      query: {
        voucherId: row.id
      }
    }
    wbVoucherFxOther(param).then(res => {
      if (res.data.msg == 'success') {
        window.open(res.data.data.url)
      }
    })
  }
}
const carousel = (val) => {
  changeIndex.value = val
  if (imgSrc.value.length - 1 == val) {
    ElMessage.warning('已经是最后一张了')
  }
  if (val == 0) {
    ElMessage.warning('当前是第一张')
  }
}
defineExpose({ init })
</script>

<style scoped lang="scss">
.leftBtnNone {
  :deep(.el-carousel__arrow--left) {
    display: none !important;
  }
}

.rightBtnNone {
  :deep(.el-carousel__arrow--right) {
    display: none !important;
  }
}
</style>
